import React from "react";
import BlogFeed from "../components/blog-feed";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Index = ({location}) => {
  return (
    <Layout>
    <Seo pathName={location.pathname} title="Thank you" />
      <section className="my-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Thank you for contacting us!</h2>
              <p>
                We’ll be in touch shortly. In the meantime, check out our blog
                feed below.
              </p>
            </div>
          </div>
        </div>
      </section>
      <BlogFeed />
    </Layout>
  );
};

export default Index;
